<template>
    <div class="post" :class="`post-${post.id}`">
        <figure v-if="post.video && Object.keys(post.video).length" class="post__media">
            <video-player :key="`vid-${post.id}`"
                          :video="{video_file:post.video, video_image: post.image ||post.thumbnail}"
                          :muted="false" controls/>
        </figure>
        <div v-if="post.content?.rendered.length" class="post__content"
             v-html="post.content.rendered || post.content"></div>
    </div>
</template>

<script>
import VideoPlayer from "./videoPlayer";

export default {
    name: "PostVideo",
    props: {post: {type: Object, required: true}},
    components: {VideoPlayer},
    computed: {
        video() {
            let video = this.post.video;
            video.video_image = this.post.thumbnail

            return video;
        }
    }
}
</script>

<style lang="scss" scoped>
.post::v-deep {
    .player-controls-wrapper {
        bottom: 0;
    }

    .player-control-bar {
        margin-top: 15px;
    }

    .video-wrapper-inner {
        position: relative;
        width: 100%;

        .vjs-player {
            width: 100%;
            max-width: 100%;
            height: 0;
            padding-top: 56.25%;

            .vjs-tech {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>
<template>
    <div class="post post--wrap" :class="`post-${post.id}`">
        <div v-if="post.quote" class="post__top">
            <p class="post__quote">{{ post.quote || post.title }}</p>
        </div>
        <div v-if="post?.content?.rendered.length" class="post__content" v-html="post.content.rendered || post.content"></div>
    </div>
</template>

<script>
export default {
    name: "PostQuote",
    props: {post: Object}
}
</script>

<style scoped>
</style>
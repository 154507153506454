export default {
    computed: {
        content() {
            if (this.post && this.post.content && this.post.content.rendered) {
                return this.post.content.rendered
            }

            if (this.post && this.post.content && typeof this.post.content === 'string') {
                return this.post.content
            }

            return ''
        }
    },
}
<template>
    <img :src="src" :srcset="srcset"/>
</template>

<script>
export default {
    name: "TheImage",
    props: {
        img: {
            type: [Object, String],
            required: true
        }
    },
    computed: {
        src() {
            console.log(this)
            if (typeof this.img === 'object' || this.img instanceof Object) {
                return this.img.src || false
            } else {
                return this.img
            }
        },
        srcset() {
            if (!this.url2x) {
                return false
            }

            return `${this.src} 1x,${this.url2x} 2x`

        },
        url2x() {
            if (typeof this.img === 'object' || this.img instanceof Object) {
                return this.img.src2x || false
            } else {
                return this.img
            }
        },

    }
}
</script>

<style scoped>

</style>
<template>
    <div class="widget">
        <div v-if="!!$slots.logo" class="widget__top">
            <figure class="widget__logo">
                <slot name="logo"/>
            </figure>
        </div>
        <div v-if="!!$slots.main" class="widget__main">
            <slot name="main"/>
        </div>
        <div v-if="!!$slots.action" class="widget__action">
            <slot name="action"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "WidgetContact",
}
</script>

<style scoped>

</style>
import { render, staticRenderFns } from "./PageSingleNav.vue?vue&type=template&id=7606674a&scoped=true"
import script from "./PageSingleNav.vue?vue&type=script&lang=js"
export * from "./PageSingleNav.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7606674a",
  null
  
)

export default component.exports
export default {
    data() {
        return {
            type: 'carousel',
            paginationEnabled: false,
            perView: 1,
            perPageCustom: [[400, 2], [768, 3], [1024, 4]],
            arrowLeft: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 34"><path d="M16.346 2.3l2.175 2.2L5.868 17.3 18.52 30.1l-2.175 2.2L1.52 17.312z" /></svg>',
            arrowRight: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 34"><path d="M2.828 31.7L.653 29.5l12.653-12.8L.653 3.9l2.175-2.2 14.825 14.998z" /></svg>',
            breakpoints: {
                1024: {
                    perView: 3
                },
                700: {
                    perView: 2,
                    navigationEnabled: false
                },
                400: {
                    perView: 1,
                    navigationEnabled: false
                }
            }
        }
    }
}

<template>
    <div>
        <post-image v-if="isFormatImage(post)" :post="post"/>
        <post-video v-else-if="isFormatVideo(post)" :post="post"/>
        <post-standard v-else-if="isFormatStandard(post)" :post="post"/>
        <post-quote v-else :post="post"/>
    </div>
</template>

<script>
import {find as _find, takeRightWhile as _takeRightWhile, takeWhile as _takeWhile} from "lodash";
import c from "../router/constants"
import BaseOverlay from "../components/baseOverlay";
import overlayMixin from "../mixins/overlayMixin";
import store from "../store";
import PostQuote from "../components/PostQuote";
import PostStandard from "../components/PostStandard";
import PostImage from "../components/PostImage";
import PostVideo from "../components/PostVideo";

export default {
    name: "PageSingleInner",
    components: {PostVideo, PostImage, PostStandard, PostQuote},
    mixins: [overlayMixin],
    data() {
        return {
            shown: false
        }
    },
    computed: {
        from() {
            return this.$route.params.from ? this.$route.params.from.path : false
        },
        post() {
            return store.post;
        },
        show() {
            return this.transitionEntered;
        },
        prevPost() {
            const posts = _takeWhile(store.posts, this.isNotCurrentPost);

            return posts.pop();
        },

        nextPost() {
            const posts = _takeRightWhile(store.posts, this.isNotCurrentPost);

            return posts.shift();
        },

        routeName() {
            return c.POST
        }
    },
    methods: {
        isFormatStandard(item) {
            if (!item) return;
            return item.format && item.format === 'standard'
        },
        isFormatImage(item) {
            if (!item) return;
            return item.format && (item.format === 'image' || item.format === 'gallery')
        },
        isFormatVideo(item) {
            if (!item) return;
            return item.format && item.format === 'video'
        },
        isFormatAside(item) {
            if (!item) return;
            return item.format && item.format === 'aside'
        },

        isNotCurrentPost($post) {
            return $post.id !== this.post.id;
        },
        getPath($post) {
            return `/about/${$post.slug}`
        },

        updateStorePost(slug) {
            if (store.posts && store.posts.length) {
                store.post = _find(store.posts, {slug});
            }
        }

    }

}
</script>

<style scoped>
</style>
<template>
    <div class="post" :class="`post-${post.id}`">
        <div class="post__header">
            <h1 class="post__title post__title--large">{{ post.title.rendered || post.title }}</h1>
        </div>
        <div class="post__float">
            <figure v-if="post.image" class="post__media post__media--large">
                <img :src="post.image"/>
            </figure>
        </div>

        <div class="post__wrap">
            <div v-if="content" class="post__content post__content--inner">
                <p v-if="post.intro" class="post__intro">{{ post.intro }}</p>
                <div v-html="content"></div>
            </div>
        </div>
    </div>
</template>

<script>

import postMixin from "../mixins/postMixin";
export default {
    name: "PostStandard",
    mixins:[postMixin],
    props: {post: Object},
}
</script>

<style scoped>

</style>
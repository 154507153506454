<template>
    <div class="post" :class="`post-${post.id}`">
        <figure class="post__media" v-if="post.gallery && post.gallery.length">

            <!--                        <img :src="post.gallery[0]" alt="" @load="onImageLoad" />-->

            <carousel :per-page="perPage" :paginationEnabled="false" ref="carousel"
                      v-images-loaded="imageLoaded"
                      :navigationEnabled="true"
                      :navigationPrevLabel="arrowLeft"
                      :navigationNextLabel="arrowRight">
                <slide v-for="(img, key) in post.gallery" :key="'id-'+key">
                    <figure class="post__media">
                        <img :src="img" :alt="post.title" width="770"/>
                    </figure>
                </slide>
            </carousel>
            <!--            <swiper :slides-per-view="1" :navigation="true">-->
            <!--                <swiper-slide v-for="(img, key) in post.gallery" :key="'id-'+key">-->
            <!--                    <img :src="img" :alt="post.title"/>-->
            <!--                </swiper-slide>-->
            <!--            </swiper>-->
        </figure>

        <figure v-else-if="post.image" class="post__media">
            <img :src="post.image"/>
        </figure>

        <div class="post__content">
            <h1 v-if="post.title" class="post__title">{{ post.title.rendered || post.title }}</h1>
            <div v-html="content"></div>
        </div>
    </div>
</template>

<script>
// Import Swiper Vue.js components
// import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import imagesLoaded from 'vue-images-loaded';
// import {Navigation} from "swiper";
// import {SwiperCore, Swiper, SwiperSlide} from 'swiper-vue2'
import postMixin from "../mixins/postMixin";
import carouselMixin from '../mixins/carouselMixin'
import actions from "../modules/fetchAxios";

// Import Swiper styles
// import 'swiper/swiper-bundle.css';

// SwiperCore.use([Navigation]);

export default {
    name: "PostImage",
    mixins: [carouselMixin, postMixin],
    directives: {imagesLoaded},
    // components: {Swiper, SwiperSlide},
    props: {post: Object},
    data() {
        return {
            ready: false,
            perPage: 1
        }
    },
    mounted() {
        // setTimeout(this.$refs.carousel.computeCarouselWidth, 300)
    },
    watch: {
        posts: {
            handler(newPosts) {
                // this.prepareSlider()
            },
            immediate: true
        }
    },
    methods: {
        onImageLoad() {
            console.log('Image Loaded')
            if (this.ready) return;

            // this.$nextTick(()=>{
            //
            // })
            setTimeout(() => {
                this.ready = true
                window.dispatchEvent(new Event('resize'))
            }, 100)

        },
        imageLoaded() {
            console.log(this.$refs.carousel)
            // this.$nextTick(()=>{
            //     this.$refs.carousel.$forceUpdate()
            // })
            if (this.$refs.carousel) {
                setTimeout(this.$refs.carousel.computeCarouselWidth, 300)
            }

        },
        async prepareSlider() {
            if (!this.post.gallery || !this.post.gallery.length) {
                return;
            }

            await actions.preloadFile(this.post.gallery[0]).then((res) => {
                console.log(res)
                if (res.status === 200) {
                    let videoBlob = res.data;
                    let vid = URL.createObjectURL(res.data); // IE10+
                    console.log(vid)
                }
            })
        },
    }
}
</script>

<style scoped>
.VueCarousel {
    width: 100%;
    height: 100%;
}

img {
    /*width: 770px;*/
    max-width: unset;
}
</style>
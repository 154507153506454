<template>
    <div>
        <transition name="slide-in" mode="out-in">
            <widget-contact v-if="post.widget && widget">
                <template v-if="widget.logo" v-slot:logo>
                    <the-image :img="widget.logo"/>
                </template>
                <template v-slot:main>
                    <h3 v-if="widget.heading">{{ widget.heading }}</h3>
                    <p>{{ widget.text }}</p>
                </template>
                <template v-slot:action>
                    <router-link to="/contact" class="button button-bordered button-icon-right">{{ widget.cta_text }}
                        <icon-arrow-forward/>
                    </router-link>
                </template>
            </widget-contact>
        </transition>
        <div class="post-nav">
            <router-link v-if="prevPost && Object.keys(prevPost).length" :to="getPath(prevPost)" class="button">
                <icon-arrow-back/>
                <span>{{ prevPost.nav_title || prevPost.title?.rendered || prevPost.title }}</span>
            </router-link>
            <span v-else></span>
            <router-link v-if="nextPost && Object.keys(nextPost).length" :to="getPath(nextPost)" class="button">
                <span>{{ nextPost.nav_title || nextPost.title?.rendered || nextPost.title }}</span>
                <icon-arrow-forward/>
            </router-link>
        </div>
    </div>
</template>

<script>
import {find as _find, takeRightWhile as _takeRightWhile, takeWhile as _takeWhile} from "lodash";
import c from "../router/constants"
import overlayMixin from "../mixins/overlayMixin";
import store from "../store";
import IconArrowForward from "../icons/iconArrowForward";
import WidgetContact from "../components/WidgetContact";
import TheImage from "../components/TheImage";

export default {
    name: "PageSingleNav",
    components: {TheImage, WidgetContact, IconArrowForward},
    mixins: [overlayMixin],
    data() {
        return {
            shown: false
        }
    },
    computed: {
        from() {
            return this.$route.params.from ? this.$route.params.from.path : false
        },
        post() {
            return store.post;
        },
        widget() {
            return store.widget;
        },
        show() {
            return this.transitionEntered;
        },
        prevPost() {
            const posts = _takeWhile(store.posts, this.isNotCurrentPost);

            return posts.pop();
        },

        nextPost() {
            const posts = _takeRightWhile(store.posts, this.isNotCurrentPost);

            return posts.shift();
        },

        routeName() {
            return c.POST
        }
    },
    methods: {
        isNotCurrentPost($post) {
            return $post.id !== this.post.id;
        },
        getPath($post) {
            return `/about/${$post.slug}`
        },
    }

}
</script>

<style scoped>
</style>